import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Signup from "./Signup";
import Subscription from "./Subscription";

function AnimatedRoutes() {
    const location = useLocation()
    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname} >
                <Route path="/" element={<Signup />}></Route>
                <Route path="/regisztracio" element={<Signup />}></Route>
                <Route path="/elofizetes" element={<Subscription />}></Route>
            </Routes>
        </AnimatePresence>

    )
}
export default AnimatedRoutes;