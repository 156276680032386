import React,{useState} from "react";
import {
	BrowserRouter as Router,
} from 'react-router-dom';
import AnimatedRoutes from "./Views/AnimatedRoutes";
function App() {
	return (
		<>
			<Router>
				<main>
					<AnimatedRoutes></AnimatedRoutes>
				</main>
			</Router>
		</>
	);
}

export default App;
