import React from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Swal from "sweetalert2";
import ApiConfig from '../config.json';

const sourceURL = `${ApiConfig.api_url}/User`;

export default function Signup() {
	const [success, setSuccess] = React.useState(false);

	function onChange(value) {
		axios.post(`${sourceURL}/recaptcha/?value=${value}`).then(data => {
			console.log(data.data.success)
			setSuccess(data.data.success)
		});
	}

	const [fields, setFields] = useState({
		first_name: "",
		last_name: "",
		email: "",
		postal_code: "",
		city: "",
		street_name: "",
		house_number: "",
		floor_apartment: "",
		password: "",
		username: "",
	})

	function handleChange(e) {
		const value = e.target.value;
		setFields({
			...fields,
			[e.target.name]: value
		});
	}

	function createPost() {
		console.log(success)
		if (
			fields.first_name &&
			fields.last_name &&
			fields.email &&
			fields.postal_code &&
			fields.city &&
			fields.street_name &&
			fields.house_number &&
			fields.password &&
			document.getElementById('check').checked &&
			document.getElementById('check1').checked &&
			success
		) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					"Accept": "*/*",
				}
			};
			axios.post(
				`${ApiConfig.api_url}/User/add/`,
				JSON.stringify({
					first_name: fields.first_name,
					last_name: fields.last_name,
					email: fields.email,
					postal_code: fields.postal_code,
					city: fields.city,
					street_name: fields.street_name,
					house_number: fields.house_number,
					floor_apartment: fields.floor_apartment,
					password: fields.password,
					username: fields.email
				}),
				config
			).then((response) => {
				console.log(response.data.id);
				sessionStorage.setItem('user_id', response.data.id);
				window.location.href = '/elofizetes'
			}).catch(function (error) {
				Swal.fire({
					icon: 'error',
					title: 'Hiba!',
					text: 'Ez az e-mail cím már regisztrálva van az adatbázisban! Próbáld meg újra!',
				})
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Hiba!',
				text: 'Valamelyik mező üresen maradt! Próbálja meg újra!',
			})
		}
	}

	return (
		<>
			<div className="w-100">
				<div className="root regisztracio">
					<header>
						<div className="d-flex flex-row justify-content-center">
							<div className="d-flex justify-content-center"></div>
							<div></div>
						</div>
					</header>
					<main className="container-xxl d-flex justify-content-center align-items-center">
						<form className="col-12 py-5">
							<div className="signupbox bg-white border rounded py-2 px-4">
								<div className="d-flex flex-column">
									<div className="row">
										<div className="col-xxl-3 col-12">
											<h5 className="p-3">
												Türki-S-Tár regisztráció -{" "}
												<span className="aTagLabel">1</span>/2
											</h5>
											<p>
												Első lépésben a leendő felhasználói fiókoddal és a
												számlázással kapcsolatos adataidat kell pontosan
												megadnod.
											</p>
											<div className="pt-5">
												<p>
													Második lépésben választanod kell majd egy előfizetési
													csomagot, valamint előre be kell fizetned a választott
													csomag díját.
												</p>
											</div>
											<div className="pt-2">
												<p>
													Az összeg beérkezése és lekönyvelése után 24 órán
													belül aktiválódik a fiókod, ezt követően tudod majd
													igénybe venni szolgáltatásunkat.
												</p>
											</div>
											<div className="pt-2">
												<p>
													A jövőbeni kellemetlenségek elkerülése érdekében
													kérjük, adataidat a lehető legpontosabban add meg!
												</p>
											</div>
										</div>
										<div className="col-xxl-3 col-lg-4 col-md-6 col-12 seperate1">
											<h5 className="p-3">Profiladatok</h5>
											<p>
												Ezek az adatok a fiókod létrehozásához és a jövőbeni
												bejelentkezésekhez szükségesek.
											</p>
											<div>
												<div className="col-md-12 pt-4 pb-3">
													<div className="d-flex flex-column">
														<label>Vezetéknév<sup>*</sup></label>
														<input
															type="text"
															className="inputs"
															name="first_name"
															onChange={handleChange} value={fields.first_name}
														/>
													</div>
												</div>
												<div className="col-md-12 pb-3">
													<div className="d-flex flex-column">
														<label>Keresztnév<sup>*</sup></label>
														<input
															type="text"
															className="inputs"
															name="last_name"
															onChange={handleChange} value={fields.last_name}
														/>
													</div>
												</div>
												<div className="col-md-12 pb-3">
													<div className="d-flex flex-column">
														<label>Email-cím<sup>*</sup></label>
														<input
															type="text"
															className="inputs"
															name="email"
															onChange={handleChange} value={fields.email}
														/>
													</div>
												</div>
												<div className="col-md-12 pb-3">
													<div className="d-flex flex-column">
														<label>Jelszó<sup>*</sup></label>
														<input
															type="password"
															className="inputs"
															name="password"
															onChange={handleChange} value={fields.password}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xxl-3 col-lg-4 col-md-6 col-12 seperate2">
											<h5 className="p-3">Számlázási adatok</h5>
											<p>
												Ezek az adatok ahhoz szükségesek, hogy az
												előfizetésedhez a számlát kiállíthassuk neked. Kérjük,
												valós címet adj meg!
											</p>
											<div>
												<div className="col-md-12 pt-4 pb-3">
													<div className="d-flex flex-column">
														<label>Irányítószám<sup>*</sup></label>
														<input
															type="text"
															className="inputs"
															name="postal_code"
															onChange={handleChange} value={fields.postal_code}
														/>
													</div>
												</div>
												<div className="col-md-12 pb-3">
													<div className="d-flex flex-column">
														<label>Település<sup>*</sup></label>
														<input
															type="text"
															className="inputs"
															name="city"
															onChange={handleChange} value={fields.city}
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-6 pb-3">
														<div className="d-flex flex-column">
															<label>Utca<sup>*</sup></label>
															<input
																type="text"
																className="inputs street"
																name="street_name"
																onChange={handleChange} value={fields.street_name}
															/>
														</div>
													</div>
													<div className="col-6">
														<div className="d-flex flex-column">
															<label className="houseNumber">Házszám<sup>*</sup></label>
															<input
																type="text"
																className="inputs houseNumber"
																name="house_number"
																onChange={handleChange} value={fields.house_number}
															/>
														</div>
													</div>
												</div>
												<div className="col-md-12 pb-3">
													<div className="d-flex flex-column">
														<label>Emelet, lakás</label>
														<input
															type="text"
															className="inputs"
															name="floor_apartment"
															onChange={handleChange} value={fields.floor_apartment}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xxl-3 col-lg-4 col-md-6 col-12">
											<h5 className="p-3">Adatvédelem és captcha</h5>
											<p>
												A regisztrációhoz el kell fogadnod Adatvédelmi
												irányelveinket, Felhasználási feltételeinket.
											</p>
											<div>
												<div className="pt-4">
													<input
														type="checkbox"
														id="check"
														className="pr-2"
													/>{" "}
													<span htmlFor="check-1" className="ml-2">
														Elolvastam és megértettem az{" "}
														<a href="/files/adatvedelmi_nyilatkozat.pdf" target={"_blank"} className="aTagLabel">
															Adatvédelmi irányelvek
														</a>
														ben foglaltakat!
													</span>
												</div>
												<div className="pt-2">
													<input
														type="checkbox"
														id="check1"
														className="pr-2"
													/>{" "}
													<span htmlFor="check-1" className="ml-2">
														Elolvastam és elfogadom a{" "}
														<a href="/files/felhasznalasi_feltetelek.pdf" target={"_blank"} className="aTagLabel">
															Felhasználási feltételek
														</a>
														et!
													</span>
												</div>
												<div className="pt-4 pb-2">
													<ReCAPTCHA
														sitekey="6LfCRSEhAAAAADtS-J5JtldwnjYXP6kJhHpByXWe"
														onChange={onChange}
													/>
												</div>
												<div className="col-md-12 pt-5">
													<a onClick={createPost}>
														<p className="signup">
															Tovább az előfizetési csomagokra
														</p>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</main>
				</div>
				<footer>
					<div className="bg-c-3 row g-0 px-3 px-lg-5 justify-content-between f-14 py-3 align-items-center">
						<div
							className="col-12 col-md-auto text-white py-1 ps-0 ps-lg-2 pb-3 pb-md-0"
							id="footermenu"
						>
							<ul>
								<li>
									<a href="/files/adatvedelmi_nyilatkozat.pdf" target={"_blank"}>Adatvédelmi nyilatkozat</a>
								</li>
								<li>
									<a href="/files/felhasznalasi_feltetelek.pdf" target={"_blank"}>Felhasználási feltételek</a>
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-auto pe-0 pe-lg-2 text-center">
							Copyright © 2022 TST - Türki S Tár Hungary
						</div>
					</div>
				</footer>
			</div>
		</>
	);
}
